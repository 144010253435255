import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "bgCelik";


var celikService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    celikMusteriEkle(musteriAdi, unvan, telefon, eposta, vergiDairesi, vergiNo, website, adres, ekBilgiler, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            tur: 2,
            token: userDataBGSurec,
            musteriAdi: musteriAdi,
            unvan: unvan,
            eposta: eposta,
            telefon: telefon,
            vergiDairesi: vergiDairesi,
            vergiNo: vergiNo,
            website: website,
            adres: adres,
            ekBilgiler: ekBilgiler,
            durum: durum,
            methodName: "musteriEkle",
            serviceName: moduleName
        }

        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    celikMusteriListesi(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            tur: 2,
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            serviceName: moduleName,
            methodName: "musteriListesi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    celikProjeEkle(sozlesmeAtamalari, musteriID, projeBaslik, adresBaslik, ulkeID, ilID, ilceID, alan, ada, parsel, baslangicTarihi, bitisTarihi, tur, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            sozlesmeAtamalari: sozlesmeAtamalari,
            musteriID: musteriID,
            projeBaslik: projeBaslik,
            adresBaslik: adresBaslik,
            ulkeID: ulkeID,
            ilID: ilID,
            ilceID: ilceID,
            alan: alan,
            ada: ada,
            parsel: parsel,
            baslangicTarihi: baslangicTarihi,
            bitisTarihi: bitisTarihi,
            tur: tur,
            durum: durum,
            methodName: "projeEkle",
            serviceName: moduleName
        }

        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    celikProjeListesi(durum, baslangic, limit, tur) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            tur,
            serviceName: moduleName,
            methodName: "projeListesi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    celikProjeFilter(musteriAdi, projeBaslik, alan, ada, parsel, konum, baslangicTarihi, bitisTarihi, durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriAdi,
            projeBaslik,
            alan,
            ada,
            parsel,
            konum,
            baslangicTarihi,
            bitisTarihi,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "projeArama"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
}

export default celikService