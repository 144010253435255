<template>
  <div class="page-table musteriTanim  mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">BGÇelik Müşteri Tanımla</div>
    <el-row :gutter="10" v-loading="loading" :element-loading-text='$t("src.views.apps.musteri.tanimlama.addLoading")'
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="musteriForm" :rules="rulesMusteri" ref="musteriForm" class="demo-ruleForm">
        <el-col :lg="18" :md="18" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10">
            <el-row>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Firma Adı" prop="musteriAdi">:
                  <el-input size="small" auto-complete="off" v-model="musteriForm.musteriAdi"
                            :placeholder="'Firma Adı Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Firma Unvanı" prop="unvan">:
                  <el-input size="small" auto-complete="off" v-model="musteriForm.unvan"
                            :placeholder="'Firma Unvanı Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Telefon" prop="telefon">:
                  <el-input size="small" auto-complete="off" v-mask="'+90(###)### ## ##'"
                            v-model="musteriForm.telefon" :placeholder="'Telefon Numarası Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="E-posta" prop="eposta">:
                  <el-input size="small" auto-complete="off" v-model="musteriForm.eposta"
                            :placeholder="'E-posta Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Vergi Dairesi" prop="vergiDairesi">:
                  <el-input size="small" auto-complete="off" v-model="musteriForm.vergiDairesi"
                            :placeholder="'Vergi Dairesi Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Vergi Numarası" prop="vergiNo">:
                  <el-input size="small" auto-complete="off" v-model="musteriForm.vergiNo"
                            v-mask="'###########'"
                            :placeholder="'Vergi Numarası Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İnternet Adresi" prop="website">:
                  <el-input size="small" auto-complete="off" v-model="musteriForm.website"
                            :placeholder="'İnternet Adresi Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Adres" prop="adres">:
                  <el-input type="textarea" auto-complete="off" :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="Adres Giriniz" v-model="musteriForm.adres">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item>
                  <el-checkbox v-model="secili">Daha Fazla Veri Eklensin mi?</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-collapse style="margin-top: 10px" v-model="activeCollapse">
            <el-collapse-item :title="'Yetkili Kişi Ek Bilgiler'" name="2" v-if="secili"
                              v-for="(ekstra, index) in ekstralar" :key="index">
              <template>
                <div>
                  <el-row class="mb-15">
                    <el-col :lg="5" :md="5" :sm="5" :xs="5" class="col-p">
                      <el-form-item label='Ad Soyad' prop="yetkiliAdSoyad">
                        <el-input v-model="ekstra.yetkiliAdSoyad"
                                  placeholder='Ad Soyad Giriniz'></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="5" :sm="5" :xs="5" class="col-p">
                      <el-form-item label='Telefon Numarası' prop="yetkiliTelefon">
                        <el-input v-mask="'+90(###)### ## ##'" v-model="ekstra.yetkiliTelefon"
                                  placeholder='Telefon Numarası Giriniz'></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="5" :sm="5" :xs="5" class="col-p">
                      <el-form-item label='E-posta' prop="yetkiliEposta">
                        <el-input v-model="ekstra.yetkiliEposta"
                                  placeholder='E-posta Giriniz'></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="5" :sm="5" :xs="5" class="col-p">
                      <el-form-item label='Ünvan' prop="yetkiliUnvan">
                        <el-input v-model="ekstra.yetkiliUnvan"
                                  placeholder='Ünvanı Giriniz'></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="2" :md="2" :sm="2" :xs="2" class="col-p">
                      <el-form-item label="Ekle" prop="ekle">
                        <el-button size="medium" type="primary" @click="addNewInfo">+</el-button>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="2" :md="2" :sm="2" :xs="2" class="col-p">
                      <el-form-item label="Çıkar" prop="cikar">
                        <el-button size="medium" type="primary"
                                   @click="subInfo(index)">-
                        </el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </el-col>
        <el-col :lg="6" :md="6" :sm="24" :xs="24">
          <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
            <el-collapse-item class="card-shadow--medium" name="8" :title='$t("src.views.apps.genel.durum")'>
              <el-row>
                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                  <el-form-item prop="durum">
                    <el-select size="small" v-model="musteriForm.durum"
                               :placeholder='$t("src.views.apps.messages.addBultenTitle")' style="width: 100%">
                      <el-option value="0"
                                 :label='$t("src.views.apps.durum.musteri.onay")'></el-option>
                      <el-option value="1"
                                 :label='$t("src.views.apps.durum.musteri.aktif")'></el-option>
                      <el-option value="2"
                                 :label='$t("src.views.apps.durum.musteri.askiyaAl")'></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-button size="mini" v-on:click="musteriEkle('musteriForm', musteriForm.durum)"
                           class="onayBtn" type="primary">
                  <label>Kaydet</label>
                </el-button>
                &nbsp;
                <el-popover placement="bottom" width="310" v-model="visibleSil">
                  <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="primary" @click="resetForm('musteriForm')">
                      {{ $t('src.views.apps.genel.yes') }}
                    </el-button>
                    <el-button size="mini" @click="visibleSil = false;">
                      {{ $t('src.views.apps.genel.no') }}
                    </el-button>
                  </div>
                  <el-button size="mini" slot="reference">
                    {{ $t('src.views.apps.genel.temizle') }}
                  </el-button>
                </el-popover>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
import draggable from "vuedraggable";

import musteriService from '../../../WSProvider/MusteriService'
import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from '@/components/event-bus'
import JQuery from 'jquery';
import celikService from "@/WSProvider/CelikService";

let $ = JQuery;

var moment = require('moment');
let time = moment().format('YYYY-MM-DD HH:mm:ss');

export default {
  name: "CelikMusteriTanimla",
  components: {
    draggable,
  },
  mounted() {
    const self = this;
  },
  data() {
    return {
      secili: false,
      ekstralar: [{}],
      loading: false,
      path: musteriService.path,
      imagePath: musteriService.imagePath,
      belgePath: musteriService.belgePath,
      imageListMain: [],
      editor: ClassicEditor,
      editorConfig: {
        language: 'tr',
      },
      activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11"],
      visibleSil: false,
      disabled: false,
      musteriForm: {
        unvan: '',
        telefon: '',
        eposta: '',
        adres: 'OSB',
        website: '',
        musteriAdi: '',
        vergiNo: '',
        vergiDairesi: '',
        durum: '1',
      },
      rulesMusteri: {
        musteriAdi: [{
          required: true,
          message: "Lütfen Firma Adı Giriniz",
          trigger: 'blur'
        }],
      },
    }
  },
  methods: {
    addNewInfo() {
      this.ekstralar.push({yetkiliTelefon: '', yetkiliEposta: '', yetkiliAdSoyad: '', yetkiliUnvan: ''});
    },
    subInfo(index) {
      this.ekstralar.splice(index, 1);
      if (this.secili && this.ekstralar.length == 0) {
        this.addNewInfo()
      }
    },
    musteriEkle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Müşteriyi tanımlamak istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            this.loading = true;
            let ekstralar = [];
            for (let i = 0; i < this.ekstralar.length; i++) {
              let o = {};
              o[`yetkiliTelefon`] = this.ekstralar[i].yetkiliTelefon;
              o[`yetkiliEposta`] = this.ekstralar[i].yetkiliEposta;
              o[`yetkiliUnvan`] = this.ekstralar[i].yetkiliUnvan;
              o[`yetkiliAdSoyad`] = this.ekstralar[i].yetkiliAdSoyad;
              ekstralar.push(o);
            }
            ekstralar = JSON.stringify(ekstralar)
            musteriService.musteriEkle(this.musteriForm.musteriAdi, this.musteriForm.unvan, this.musteriForm.telefon, this.musteriForm.eposta, this.musteriForm.vergiDairesi, this.musteriForm.vergiNo, this.musteriForm.website, this.musteriForm.adres, ekstralar, this.musteriForm.durum, 2).then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token)
                notification.Status("success", this, response.msg)
                EventBus.$emit("celikMusteriListesi")
                functions.sayfaKapat("bgcelikmusteri-tanimla", this);
                this.resetForm(formName)
              }
              this.loading = false;
            }).catch(err => {
              if (err.responseJSON) {
                let error = err.responseJSON
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg)
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            })
          }).catch((e) => {
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
    },
  }
}

</script>
